%course-column {
  flex-basis: 35%;
  flex-shrink: 0;
}

.mdd-training {
  &--emphasized {
    font-size: 16px;
    font-weight: bold;
  }
  &--separator {
    margin-right: 16px;
    margin-left: 16px;
    color: #e1e1e1;
  }
  &--container {
    font-size: 16px;
    height: calc(100% - 3.5rem - 10px);
    overflow-y: auto;
    border-left: 1px solid lightgray;
    > .mdd-expansion-panel--panel {
      border: 1px solid #e1e1e1;
      border-radius: 5px;
      margin: 0 24px 16px 24px !important;
      // below are to set correct padding on the summary and course table
      > .mdd-expansion-panel--summary {
        padding-left: 24px;
        padding-right: 24px;
        height: 60px;
      }
      > :nth-child(2) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
  &--header-div {
    display: flex;
    padding-top: 7px;
    color: #0267b8;
    cursor: pointer;
    &--button {
      padding: 10px 0 24px 24px;
      flex-basis: 200px;
      > button {
        text-transform: none;
      }
    }
    &--placeholder {
      flex-grow: 1;
    }
    &--expand-collapse {
      flex-basis: 225px;
      position: relative;
      top: 16px;
      padding-right: 16px;
      & > :first-child {
        padding-right: 10px;
      }
      & > :nth-child(2) {
        // separator
        color: lightgray;
        opacity: 0.5;
        cursor: auto;
      }
      & > :nth-child(3) {
        padding-left: 10px;
      }
      &--disabled {
        color: #0267b8;
        opacity: 0.5;
        cursor: auto;
      }
    }
  }
  &--action-button {
    height: 28px;
    width: 90px;
    &--smaller {
      width: 40px;
    }
  }
  &--panel {
    &--LP {
      border: 1px solid #e1e1e1;
      margin-bottom: 20px;
      &--checkbox {
        &--disabled {
          // To make the checkbox being disabled more obvious - with the default styling
          //  it's hard to tell when it's disabled
          opacity: 0.4;
        }
      }
      > .mdd-expansion-panel--summary {
        padding-left: 8px;
        > div {
          // remove the excessive margin in the panel header
          margin: 0;
        }
      }
    }
    > .mdd-training--panel--LP {
      background-color: #f9f9f9;
      border-radius: 4px;
      > .mdd-expansion-panel--summary {
        padding-left: 8px;
      }
    }
  }
  &--tab {
    > .MuiTab-wrapper {
      text-transform: none;
    }
  }

  &--qual-header {
    display: flex;
    &--status {
      flex-grow: 1;
    }
    &--override {
      flex-basis: 80px;
      > button {
        text-transform: none;
      }
    }
  }

  &--table {
    &--container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background-color: white;
      border: 1px solid #e1e1e1;
      border-radius: 5px;
    }
    &--row-container {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      align-items: center;
    }
    &--cell {
      height: 2.5rem;
      margin-left: 12px;
      padding-top: 12px;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &--indicator {
        flex-basis: 2%;
        flex-shrink: 0;
      }
      &--course {
        @extend %course-column;
      }
      &--course-link {
        @extend %course-column;
        color: #0267b8;
        cursor: pointer;
      }
      &--status {
        flex-basis: 15%;
        flex-shrink: 0;
      }
      &--date {
        flex-basis: 20%;
      }
      &--course-info-icon {
        position: relative;
        top: 3px;
        left: 8px;
      }
      &--button {
        flex-basis: 25%;
        padding-right: 32px;
        text-align: right;
        > span > button {
          text-transform: none;
        }
      }
      &--header {
        font-weight: bold;
        margin-bottom: 10px;
        padding-top: 14px;
      }
    }
    &--divider {
      flex-basis: 100%;
      opacity: 0.4;
      margin-left: 28px;
      margin-right: 24px;
    }
  }
  &--unassign {
    color: #0267b8;
    &-disabled {
      color: lightgray;
    }
  }
  &--pending {
    font-style: italic;
  }
  &--info-icon {
    position: relative;
    top: 4px;
    left: 7px;
  }
  &--commentIcon {
    position: relative;
    top: 6px;
  }

  &--course-scores {
    &--header {
      font-size: 18px;
      font-weight: bold;
      margin-left: 24px;
      margin-top: 16px;
    }
    &--dialog {
      &--content {
        width: 100%;
      }
      &--confirm-button > div > button {
        width: 100px;
        text-transform: none;
      }
      &--actions {
        > div {
          > .mdd-training--course-scores--button-hidden {
            // Hide what is normally the Close button in the dialog buttons component.
            // I am using what is normally the Save button as the Close since its
            // styling matches up better with what we want.
            display: none;
          }
          > .mdd-training--course-scores--dialog--button-close {
            margin-right: 32px;
          }
        }
      }
    }
    &--panel {
      max-height: 60vh;
      padding-right: 0;
      &--container {
        > .mdd-training--course-scores--panel {
          padding-right: 4px;
        }
      }
      &--table {
        max-height: 53vh;
        overflow-y: auto;
        > table {
          > thead > tr > th {
            font-weight: bold;
          }
          > tbody > tr > td {
            white-space: normal;
            word-break: break-word;
          }
        }
      }
    }
  }
}
