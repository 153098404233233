.mdd-override-dialog {
  &--container {
    display: flex;
  }
  &--left-panel {
    flex-basis: 40%;
    border-right: 1px solid #ebebeb;
  }
  &--right-panel {
    flex-basis: 60%;
    padding-left: 40px;
  }
  &--label {
    color: #747474;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    &--no-margin {
      color: #747474;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  &--text {
    padding-bottom: 16px;
    color: #747474;
  }
  &--content {
    width: 700px;
  }
  &--select {
    width: 335px;
    margin-top: 6px !important;
    border: 1px solid lightgray;
    &--div {
      height: 32px;
      border: 1px solid lightgray;
      width: 100% !important; // need important since front end lib has it (bad library!)
    }
    > div > div {
      padding-left: 5px;
    }
    > div > input {
      border: 1px solid lightgray;
      padding-left: 5px;
    }
  }
  &--edit-comment {
    &--input {
      padding-right: 16px !important; // front-end-lib has !important so need to override it
      margin-top: 10px !important;
      > div {
        padding: 12px;
      }
    }
  }
}

.mdd-override-dialog--right-panel > .mdd-override-dialog--datePicker {
  border: 1px solid lightgray;
  > div {
    margin-left: 5px;
    width: 328px;
    // color for calendar icon
    > div > button > span > svg {
      color: #057cb1;
    }
  }
}
