.mdd-chkbox {
  display: flex;
  align-items: center;

  > span:not([class*="disabled"]) {
    + label {
      cursor: pointer;
    }
  }

  > span {
    padding-left: 0;
  }
}
