.mdd-assign-lp {
  &--no-data {
    &--container {
      text-align: center;
      width: 100%;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    &--text {
      display: inline-block;
      color: gray;
    }
  }

  &--main {
    height: 100%;
  }

  &--container {
    margin: 24px;
    > .tbl--container > .tbl {
      > .tbl--body {
        max-height: 40vh;
      }
      > .tbl--header > .tbl--header-row > :first-child {
        // formats the first column in the header so the checkbox column doesn't take up equal space since it looks bad
        max-width: 48px;
        left: 5px; // also move it over a bit to line up correctly
      }

      > button {
        margin-bottom: 24px;
      }
    }
  }
  &--button {
    width: 120px;
    margin-top: 24px;
    > span {
      text-transform: none;
      font-size: 0.9rem;
    }
  }
  &--divSelect {
    margin-bottom: 24px;
  }
  &--select {
    width: 500px;
    margin-top: 6px !important;
    border: 1px solid lightgray;
    &--div {
      height: 32px;
      border: 1px solid lightgray;
      width: 100% !important; // need important since front end lib has it
      > #learningPlan {
        // gets rid of the annoying gray blob MUI surrounds the dropdown with when selected
        background-color: transparent;
      }
    }

    > div > div {
      padding-left: 5px;
    }
    > div > input {
      border: 1px solid lightgray;
      padding-left: 5px;
    }
  }
  &--infiniteScroll-container {
    > .infinitescroll > .infinitescroll--content > .tbl--body-row {
      > :first-child {
        // formats the first column so the checkbox column doesn't take up equal space since it looks bad
        display: block;
        max-width: 48px;
      }
      // Because of playing around with the checkbox column format, location of the
      // other columns are thrown slightly off so fix them.
      > :nth-child(2) {
        position: relative;
        left: -3px;
      }
      > :nth-child(3) {
        position: relative;
        left: -5px;
      }
      > :nth-child(4) {
        position: relative;
        left: -7px;
      }
      > :nth-child(5) {
        position: relative;
        left: -9px;
      }
    }
    > .infinitescroll {
      overflow-y: hidden; // don't want this scrollbar since there it's a duplicate
      > .infinitescroll--content > .tbl--body-row > :last-child {
        // assigned circle check column
        color: green;
        position: relative;
        left: 24px;
      }
    }
  }
}
