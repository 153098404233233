.mdd-icon {
  &-span {
    &--text {
      position: relative;
      top: -4px;
    }
    &--icon {
      color: #f09c00;
      position: relative;
      top: 3px;
      left: 4px;
      > svg {
        cursor: auto;
      }
    }
  }
}
