.bracket-Select {
  max-height: 350px;
  > div > ul {
    > :first-child {
      height: 24px;
    }
    > li {
      min-height: 24px;
      padding-bottom: 11px;
      padding-top: 11px;
      padding-left: 16px;
      display: block;
    }
  }
}
.validated-selectchip-field__select {
  > div {
    height: 100%;
  }
}
