.mdd-add-edit-study {
  &--info-icon {
    position: relative;
    left: 340px;
    top: -38px;
  }
  // The info icon throws off the alignment so we need to adjust
  &--form-body > .mdd-add-edit-study--internal-name {
    margin-bottom: 0 !important; // need important since FEL has it
  }
}
