.mdd-expansion-panel {
  &--header {
    font-size: 16px;
    width: 100%;
    font-weight: 500;
  }
  &--panel {
    margin-left: 24px;
    > :first-child {
      padding-top: 0;
      width: 100%;
    }
  }
  &--details {
    line-height: 1.5rem;
    > :first-child {
      padding-top: 0;
      margin-top: -10px;
      width: 100%;
    }
  }
}
