.mdd-dialog {
  &--content-light {
    color: rgba(0, 0, 0, 0.54);
  }

  &--no-scroll {
    // dialog
    overflow-y: initial !important;

    // paper
    & > div:nth-child(3) > div {
      overflow-y: initial !important;
      // content
      & > div[class*="content"] {
        overflow-y: initial !important;
      }
    }
  }

  &--title {
    > h2 {
      width: 100%;
      color: black;
      display: flex;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: 0.0075em;
    }
  }

  &--content {
    width: 80vw;
    overflow-x: hidden;
    overflow: visible !important;

    > span {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    &-small {
      width: 40vw;
    }

    &-medium {
      width: 60vw;
    }

    &-100percent {
      width: 100%;
    }
  }
}

.form-dialog-title {
  padding-bottom: 0px !important;
  padding-top: 15px !important;

  > h6 {
    width: 100%;
    color: black;
    display: flex;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }
}

.form-dialog-contentText {
  display: "flex";
  flex-direction: "column";
  width: 300px;
  margin-bottom: 2rem;
}

.form-dialog-paper {
  overflow-y: initial !important;
}

.form-dialog-title-content {
  font-weight: 400;
  line-height: 1.5;
  color: "#cccccc" !important;
  font-size: "1rem" !important;
  letter-spacing: "0.00938em" !important;
  margin-bottom: "16px" !important;
  margin-top: "20px" !important;
}

.mdd-form-delete-dialog-content {
  margin-bottom: "20px" !important;
  width: "500px" !important;
  margin-top: "20px" !important;
  color: "#cccccc" !important;
}

.mdd-form-dialog {
  max-width: 800px;

  &-content {
    overflow-y: initial !important;
  }

  &-action {
    flex: 0 0 auto;
    margin: 8px 15px 8px 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-container {
    padding: 0px;
    margin-right: 15px !important;

    &--primary__button {
      text-transform: inherit !important;
      width: 90px;
      padding: 0px 0px;
      margin: 0px 4px;
    }

    &--secondary__button {
      text-transform: inherit !important;
      width: 90px;
      padding: 0px 0px 0px 0px;
      margin: 0px 4px;
    }
  }

  &--content {
    overflow-y: initial !important;
  }

  &--action {
    flex: 0 0 auto;
    margin: 8px 15px 8px 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &--container {
    padding: 0px;
    margin-right: 15px !important;

    &--primary__button {
      text-transform: inherit !important;
      width: 90px;
      padding: 0px 0px;
      margin: 0px 4px;
    }

    &--secondary__button {
      text-transform: inherit !important;
      width: 90px;
      padding: 0px 0px 0px 0px;
      margin: 0px 4px;
    }
  }
}

.mdd-pi-dialog {
  &--title {
    > h6 {
      width: 100%;
      color: black;
      display: flex;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: 0.0075em;
    }
  }

  &--content {
    width: 80vw;

    > span {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
}

.mdd-form-delete-dialog {
  max-width: 800px;

  &-content {
    overflow-y: initial !important;
    margin-top: 12px !important;
    margin-bottom: 10px !important;
    color: #000000 !important;
  }

  &-action {
    flex: 0 0 auto;
    margin: 28px 15px 8px 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-container {
    padding: 0px;
    margin-right: 15px !important;

    &--primary__button {
      text-transform: inherit !important;
      width: 90px;
      padding: 0px 0px;
      margin: 0px 4px;
    }

    &--secondary__button {
      text-transform: inherit !important;
      width: 90px;
      padding: 0px 0px 0px 0px;
      margin: 0px 4px;
    }
  }
}

button.mdd-dialog--close-button {
  position: absolute;
  top: 12px;
  right: 5px;
}

#btnDialogClose {
  text-transform: none;
}
#btnDialogSave {
  text-transform: none;
}
