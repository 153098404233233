.mdd-qualification {
  &--outlined-input {
    padding-top: 0;
    padding-bottom: 0;
  }
  &--disabled-text {
    color: lightgray;
  }
  &--content {
    &--grid {
      > .typeahead-select {
        > .typeahead-select--actions {
          right: 1px; // move dropdown arrow back into outlined box
          top: -3px;
        }
      }
    }
    &--grid-with-border {
      border-left: 1px solid antiquewhite;
      > #chkStudyQualifcationActiveDiv {
        padding-top: 8px;
      }
    }

    &--select {
      margin-top: 8px !important;
      border: 1px solid lightgray;
      &--div {
        border: 1px solid lightgray;
        width: 100% !important; // need important since front end lib has it (bad library!)
      }
      > div > div {
        padding-left: 5px;
      }
      > div > input {
        border: 1px solid lightgray;
        padding-left: 5px;
      }
    }
    &--header {
      &-padded {
        padding-top: 1rem;
      }
    }
    &--legacy-LP-info {
      &--label {
        margin-top: 16px;
        font-weight: bold;
      }
      &--text {
        margin-top: 8px;
      }
    }
  }
}

#divStudyQualDialogContent {
  padding-top: 1.5rem;
}
#FormControl_mdd-qualification--select-multiple-chip {
  margin-top: -8px;
  width: 100% !important; // need important since front end lib has it (evil!)
  > label {
    padding-left: 5px;
    color: rgba(
      0,
      0,
      0,
      0.38
    ) !important; // need important since front-end-lib has it
  }
}

input.mdd-qualification--outlined-input {
  padding-top: 7px;
  padding-bottom: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
}

input.mdd-qualification--content--input {
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 16px;
}
