.mdd-study-scores {
  &--panel {
    padding: 24px;
    &--top {
      max-height: 5rem;
      overflow-y: auto;
    }
    &--hidden {
      display: none;
    }
  }
  &--info-icon {
    position: relative;
    top: 4px;
    left: 8px;
  }
  &--validation-error {
    font-size: 12px;
    color: rgb(244, 67, 54);
    margin-top: -4px;
  }
  &--threshold-width > div {
    width: 80%;
  }
  &--container {
    > .mdd-form {
      height: calc(100% - 64px);
      > .mdd-form--container {
        padding: 24px;
      }
    }
  }
  &--divScoresInfiniteScroll {
    height: auto;
    > .infinitescroll {
      overflow-y: auto;
      // adjust the table row a bit to line the row data up better with the headers
      > .infinitescroll--content > .tbl--body-row {
        position: relative;
        // hide the first column since it's just a placeholder for legacy/non-legacy and not to be shown
        > :first-child {
          display: none;
        }
        // first displayed column is off a bit (due to the hidden column?) so move it so it lines up better
        > :nth-child(2) {
          position: relative;
          right: 5px;
        }
      }
    }
  }
  &--divCountriesInfiniteScroll {
    height: auto;
    > .infinitescroll {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  &--button-container {
    float: right;
    position: relative;
    top: -3px;
    > button {
      text-transform: none;
      text-transform: none;
      height: 28px;
      width: 90px;
      > span {
        font-size: 12px;
      }
    }
    .mdd-study-scores--button-secondary {
      background-color: #c75d40;
      margin-left: 16px;
      color: white;
      &:hover {
        background-color: #fa3d09;
      }
    }
  }
  &--flex-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    &--column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
    &--child-fullwidth {
      min-height: 8rem;
      height: 100%;
      margin-top: 24px;
      padding: 15px 24px 0 24px;
      flex-basis: 98%;
      border: 1px solid #e1e1e1;
      border-radius: 3px;
      > .tbl--container {
        margin-top: 12px;
      }
    }
  }
  &--section {
    &--container {
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
      margin-left: 24px;
      margin-top: 12px;
    }
    &--header {
      font-weight: bold;
    }
    &--column {
      flex-basis: 31%;
      padding-bottom: 12px;
      padding-top: 12px;
      &--icon {
        flex-basis: 24px;
        margin-left: auto;
        position: relative;
        top: 10px;
        > svg {
          font-size: 20px;
        }
      }
    }
  }
  &--separator {
    position: relative;
    top: -43px;
    opacity: 0.3;
  }
  &--lock-button > div > button {
    width: 100px;
    text-transform: none;
  }
  &--country-link {
    color: #057cb1;
    cursor: pointer;
  }
}

#countryHeader_filter > .txtField--container {
  max-width: 20rem;
}

#divBottomPane {
  overflow-y: auto;
  max-height: calc(100vh - 550px);
}

#divStudyScoresHeader {
  > p {
    margin-left: 24px;
  }
}
#divStudyScoresBody {
  height: 100%;
  border: 1px solid #e1e1e1;
  box-shadow: none;
  // padding for table body
  > #bktDataTable_container {
    margin-left: 12px;
    margin-right: 12px;
  }
}
#btnThresholdsCancel {
  text-transform: none;
}
#btnThresholdsModify {
  text-transform: none;
}

// hide the first column header since it's just a placeholder for legacy/non-lecagy and not to be shown
.tbl--header-row > .mdd-study-scores--hidden-column-header {
  display: none;
}
