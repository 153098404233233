.mdd-add-study-site-personnel-dialog {
  &--action-button {
    width: 132px;
    height: 44px;
  }
  &--panel-container {
    margin-left: 0;
    margin-right: 0;
    flex-wrap: nowrap;
  }
  &--panel {
    padding: 0;
    height: 25vh;
    overflow-y: auto;
    > table {
      > thead > tr > th {
        font-weight: bold;
        > .mdd-validated-text-field-div--container {
          width: 100%;
          max-width: 100%;
        }
      }
      > tbody > tr > td {
        white-space: normal;
        word-break: break-word;
      }
    }
    &--header {
      height: 1rem;
      padding: 0;
      margin-bottom: 0;
    }
    &--buttons {
      height: 30vh;
      padding-left: 24px;
      padding-right: 24px;
      > div > button {
        height: 32px;
        margin-top: 8px;
      }
    }
  }
  &--disabled-text {
    color: #bdbdbd;
  }
  &--searching {
    &--container {
      padding-top: 48px;
      text-align: center;
      width: 100%;
    }
    &--spinner {
      display: inline-block;
    }
  }
  &--assigned-table {
    > tbody > tr > :nth-child(5) {
      > div > .validated-selectchip-field__select {
        max-width: 200px;
      }
    }
  }
}
#divAddNewPersonnelAction {
  position: relative;
  top: -80px;
}
// shrink the previous personnel's container height to space things better
#divAddNewPersonnelContent > :nth-child(2) {
  height: 27vh;
}
