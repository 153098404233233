.mdd-validated-text-field {
  &--label-padded {
    padding-top: 6px;
  }
  &-div {
    &--container {
      line-height: 1.25rem;
      max-width: 60%;

      > .txtField--container {
        > div {
          margin-top: 5px;
          > div {
            > input {
              // ellipsis for overflow on text fields
              text-overflow: ellipsis;
              padding: 5px;
            }
          }
        }
      }
    }
  }
}
