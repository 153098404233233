@mixin one-line-table-header {
  height: 2rem;
  border-bottom: 1px solid lightgray;
  margin-right: 17px;
}

.mdd-res-draft {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  height: calc(100% - 3.5rem);
  padding: 40px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);

  &-panel {
    height: 100vh;
    background-color: #f9f9f9;
    border: 1px solid #747474;
    border-radius: 4px;
    margin: 0;
  }

  &--tableContainer {
    height: 100%;
    overflow: auto;
  }

  .infinitescroll {
    max-height: calc(100vh - 45rem) !important;

    @media (max-width: 1550px) {
      max-height: calc(100vh - 35rem) !important;
    }
  }

  button {
    text-transform: none;
  }

  .fel-panel-container--panel-full {
    border: none;
    padding-bottom: 0;
  }

  .tbl--header-cell,
  .tbl--body-cell {
    margin: 0;
  }

  .tbl--header-label {
    padding: 0;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #707070;
    margin-bottom: 5px;
    font-weight: normal;
    h2 {
      font-weight: inherit;
    }
  }

  .nav-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .actions button:first-of-type {
      margin-right: 8px;
    }
  }

  &--tableFooter {
    display: none;
  }

  .mdd-grid--with-menu {
    height: 100%;
  }

  .mdd-grid > div:first-child p {
    margin-left: 1rem;
  }

  .mdd-grid > div:first-child button {
    margin-right: 1rem;
  }

  .mdd-grid > div:last-child {
    padding-bottom: 1rem;
  }

  &--tab-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    h3,
    h4 {
      font-weight: bold;
    }

    h4 {
      margin-top: 1.5rem;
    }

    &--paragraph {
      margin-top: 1.5rem;
    }

    &--fields {
      height: 80px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
    }

    &--demographic-fields {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
    }

    ul {
      margin-top: 1rem;
      list-style: none;

      li {
        margin-top: 0.2rem;
        margin-right: 50px;
      }
    }

    .mdd-form {
      border-left: 0;

      &--container {
        padding: 20px 0px 0px 0px;
      }
    }

    .tbl--body {
      min-height: 10rem;
      height: 100%;

      ul {
        margin-top: 0;
      }

      &-row-actions svg {
        width: 1em;
      }
    }

    .icon-disabled {
      color: rgba(0, 0, 0, 0.26);
      pointer-events: none;
    }

    #divresExperienceGeneralContainerBody,
    #divresExperienceIndcationContainerBody,
    #divresScalesContainerBody {
      height: 100%;
    }

    .mdd-chkbox,
    #activeHeader_label,
    #deleteHeader_label {
      justify-content: center;
    }

    .tbl .tableHead {
      @include one-line-table-header;
    }

    .tbl--body-cell:nth-last-child(1),
    .tbl--body-cell:nth-last-child(2),
    .tbl--header-cell:nth-last-child(1),
    .tbl--header-cell:nth-last-child(2) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 70px !important;
    }

    .infinitescroll--content > * {
      margin: 0;
    }

    .MuiIconButton-root {
      padding: 0;
    }
  }

  &--dialog {
    width: auto;

    &-content {
      padding: 0 !important;
    }

    &-search {
      padding-bottom: 20px;

      p {
        margin-bottom: 8px;
      }
    }

    .MuiPaper-root {
      padding-top: 16px;
    }

    .tbl .tableHead {
      @include one-line-table-header;
    }
  }

  &--li {
    padding-left: 0;
    list-style-type: none;
  }

  .tbl--header-row,
  .tbl--body-row {
    gap: 10px;
  }

  &--add-table {
    height: 400px;
    .tbl--body {
      height: calc(100% - 2rem);
    }
    .tbl--header-cell:first-child,
    .tbl--body-cell:first-child {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 50px !important;
    }

    .tbl--header-row,
    .tbl--body-row {
      gap: 10px;
    }

    .tbl--body-row,
    .tbl--body-cell,
    .tbl--header-cell {
      padding: 0;
      margin: 0;
    }

    .mdd-chkbox {
      .MuiIconButton-root {
        padding: 0;
      }
      justify-content: center;
    }
  }
}
