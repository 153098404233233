%mdd-panel {
  height: 100%;
  border: 1px solid #e1e1e1;
  margin-bottom: 24px;
  padding: 24px;
}
%mdd-panel-no-border {
  height: 100%;
  margin-bottom: 24px;
  padding: 24px;
}

.mdd-panel-container {
  display: flex;
  justify-content: space-between;
  margin-left: 24px;
  margin-right: 24px;
  flex-wrap: wrap;
  margin-top: 24px;
  &--panel-onethird {
    @extend %mdd-panel;
    flex-basis: 32%;
  }
  &--panel-half {
    @extend %mdd-panel;
    flex-basis: 49%;
  }
  &--panel-twothirds {
    @extend %mdd-panel;
    flex-basis: 65%;
  }
  &--panel-full {
    @extend %mdd-panel;
    flex-basis: 100%;
  }
  &--panel-custom {
    @extend %mdd-panel;
  }
  &--panel-onethird-no-border {
    @extend %mdd-panel-no-border;
    flex-basis: 32%;
  }
  &--panel-half-no-border {
    @extend %mdd-panel-no-border;
    flex-basis: 49%;
  }
  &--panel-twothirds-no-border {
    @extend %mdd-panel-no-border;
    flex-basis: 65%;
  }
  &--panel-full-no-border {
    @extend %mdd-panel-no-border;
    flex-basis: 100%;
  }
  &--panel-custom-no-border {
    @extend %mdd-panel-no-border;
  }
}
