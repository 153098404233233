.mdd-edit-course-scores {
  &--buttons {
    position: relative;
    right: 24px;
  }
  &--panel {
    padding: 24px;
    &--top {
      max-height: 5rem;
      overflow-y: auto;
    }
    &--hidden {
      display: none;
    }
  }
  &--top-panel {
    max-height: 60vh;
    min-height: 60vh;
    overflow-y: auto;
    // hide table footer (total results)
    > .tbl--container > .tbl {
      > .tbl--header {
        height: 5rem;
        // move the header to the right a bit so it lines up better
        > .tbl--header-row {
          position: relative;
          left: 5px;
        }
      }
      > .tbl--body {
        position: relative;
        top: -8px;
      }
      > .tbl--footer {
        display: none;
      }
    }
    > .tbl--container {
      > .tbl {
        > .tbl--body {
          > .infinitescroll--container {
            > .infinitescroll {
              overflow-y: hidden;
            }
          }
        }
      }
    }
  }
  &--questions {
    &--row {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    &--header {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 24px;
    }
    &--question-column {
      flex-basis: 25%;
      flex-wrap: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      flex-shrink: 0;
      flex-grow: 0;
    }
    &--column {
      flex-basis: 15%;
      width: 100%;
      flex-shrink: 0;
    }
    &--textbox {
      position: relative;
      top: 3px;
      // position the helper (validation error text) better
      > .mdd-validated-text-field-div--container
        > .txtField--container
        > div
        > p {
        margin: 0;
      }
    }
    &--button {
      float: right;
      margin-right: 8px;
      > button {
        height: 32px;
        text-transform: none;
      }
    }
  }
  &--details {
    &--label {
      font-weight: bold;
      font-size: 14px;
      margin-top: 12px;
    }
    &--value {
      font-size: 14px;
      margin-top: 8px;
    }
    &--QC {
      margin-top: 6px;
    }
    &--padding {
      padding-top: 16px;
    }
  }
  &--close-button {
    padding-right: 2px;
    text-align: right;
    > button {
      height: 44px;
      width: 120px;
      text-transform: none;
    }
  }
  &--val-error {
    font-size: 12px;
    color: red;
    position: relative;
    left: 8px;
  }
  &--info-icon {
    position: relative;
    top: 4px;
    left: 8px;
  }
  &--updatedResponse {
    max-width: 250px;
  }
  &--updatedAnswerSet {
    margin-top: 12px;
  }
  &--disabled-text {
    color: lightgray;
  }
}

.mdd-training--course-scores--dialog--actions
  > .mdd-edit-course-scores--delete {
  background-color: #c75d40;
  color: white;
  position: absolute;
  left: 48px;
  > span {
    text-transform: none;
  }
}
.mdd-training--course-scores--dialog--actions
  > .mdd-edit-course-scores--delete {
  background-color: #c75d40;
  color: white;
  position: absolute;
  left: 48px;
}
.mdd-training--course-scores--dialog--actions
  > .mdd-edit-course-scores--delete--disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: white;
  position: absolute;
  left: 48px;
  > span {
    text-transform: none;
  }
}

.mdd-training--course-scores--dialog--actions
  > .mdd-edit-course-scores--delete:hover {
  background-color: #fa3d09;
}

#divDeleteConfirmDialogContent {
  width: 100%;
}
