.mdd-study-site {
  &--form-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 16px 20px 16px;
    width: 100%;
  }
  &--save-button {
    width: 120px;
  }
  &--search-button {
    width: 100px;
    height: 30px;
    &--padding {
      position: relative;
      top: 12px;
    }
  }
  &--select {
    width: 200px;
    margin-top: 6px !important;
    border: 1px solid lightgray;
    &--div {
      height: 32px;
      border: 1px solid lightgray;
      width: 100% !important; // need important since front end lib has it (bad library!)
    }
    > div > div {
      padding-left: 5px;
    }
    > div > input {
      border: 1px solid lightgray;
      padding-left: 5px;
    }
  }
  &--protocols {
    &--none {
      padding: 20px 25px 0 25px;
      color: red;
    }
    &--container {
      > :first-child {
        margin-top: -6px; // bring 1st protocol up to line up with the 1st header in the General tab
      }
    }
    &--label {
      padding-top: 8px;
      line-height: 1.5rem;
    }
  }

  &-div {
    &--header {
      margin-bottom: 7px;
      font-size: 18px;
    }
    &--label {
      font-size: 16px;
      margin-top: 7px;
      font-weight: bold;
      &--with-padding {
        font-size: 16px;
        margin-top: 7px;
        font-weight: bold;
        padding-top: 24px;
        > .tooltip {
          display: inline-block;
          top: 3px;
          position: relative;
          top: 5px;
          left: 7px;
        }
      }
    }
    &--sponsor-label {
      font-size: 16px;
      margin-top: 18px;
      font-weight: bold;
    }
    &--text {
      font-size: 16px;
    }
    &--flex-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      line-height: 1.75rem;
    }
    &--flex-child-left {
      flex-basis: 54%;
      border: 1px solid #e1e1e1;
      padding: 15px;
      min-width: 300px;
      display: flex;
      flex-direction: row;
      &--column1 {
        flex-basis: 55%;
        > .txtField--container {
          > div {
            margin-top: 5px;
            > div {
              > input {
                // ellipsis for overflow on text fields
                text-overflow: ellipsis;
                padding: 5px;
              }
            }
          }
        }
        > .typeahead-select {
          width: 90%;
        }
      }
      &--column2 {
        flex-basis: 45%;
        padding-left: 20px;
      }
    }
    &--flex-child-right {
      flex-basis: 44%;
      border: 1px solid #e1e1e1;
      padding: 15px;
      min-width: 300px;
      > button > span > svg {
        color: white;
      }
      > .mdd-study-site-personnel--fab {
        position: absolute;
      }
    }
  }
  &-tbl {
    &--header-row {
      margin-bottom: 0px !important;
      padding-bottom: 10px;

      &:first-child {
        * {
          width: 250px;
        }
      }

      &:last-child {
        position: relative;

        > :first-child {
          position: absolute;
          left: 2%;
        }

        > :last-child {
          position: absolute;
          right: 2%;

          > * {
            justify-content: center;
          }
        }
      }
    }

    &-div {
      &--search {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 360px;

        & > .tooltip {
          height: 25px;
          &:hover {
            background-color: lightgray;
            border-radius: 10px 10px 10px 10px;
          }
          & > .tooltip--bottom-start {
            top: -10rem;
          }
        }

        & > :first-child {
          flex-basis: 20%;
          align-self: flex-end;
          margin-bottom: 8px;
        }

        & > :nth-child(2) {
          flex-basis: 65%;
        }

        & > :last-child {
          flex-basis: 7%;
          position: relative;
          top: 10px;
        }
      }
    }

    &--body-row {
      position: relative;

      & > :first-child {
        width: calc(100% - 251px - 4%);
        position: absolute;
        left: 2%;
        margin: initial;
      }

      & > :last-child {
        position: absolute;
        right: 150px;
        border-radius: 50%;

        & > :first-child {
          border-radius: 50%;
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background-color: gray;
          }
        }
      }
    }

    &-history {
      min-height: 100px;
      width: 600px;

      & > :first-child {
        height: 2rem;
      }

      & > :nth-child(2) {
        padding: 0px;
      }

      & > :last-child {
        display: none;
      }

      & * {
        overflow-y: visible !important;
      }

      &--header {
        &-row {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          color: $color-white;
          width: 100%;
          font-weight: bold;
          text-decoration: underline;
          :first-child {
            flex-basis: 51% !important;
          }
          :nth-child(2) {
            flex-basis: 25% !important;
          }
          :last-child {
            overflow: visible;
            flex-basis: 24% !important;
          }
        }
      }

      &--body {
        &-row {
          font-weight: 100;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin: 0;
          border-bottom: none !important;
          min-height: 1rem !important;
          :first-child {
            flex-basis: 50% !important;
            padding-left: 5px;
          }
          :nth-child(2) {
            flex-basis: 25% !important;
          }
          :last-child {
            overflow: visible;
            flex-basis: 25% !important;
          }
        }
      }

      &--body-cell {
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px !important;
        line-height: 1.75rem;
        color: lightgray;
      }
    }
  }
  &--no-data {
    &--container {
      text-align: center;
      width: 100%;
    }
    &--text {
      display: inline-block;
      color: red;
      font-size: 0.8rem;
      padding-top: 16px;
    }
  }
  &--inactive-protocols {
    font-size: 0.8rem;
    color: red;
  }
}
