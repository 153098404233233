.mdd-main-div {
  height: 100%;
}

.mdd-progress {
  &--spinner {
    position: fixed;
    z-index: 2000;
    top: 50%;
    left: 50%;
  }
  &--div {
    background-color: #d7dbdd;
    position: fixed;
    opacity: 0.5;
    z-index: 2000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.tooltip {
  font-family: "Noto Sans";
}
