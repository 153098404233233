.mdd-typeahead-container {
  margin-top: 15px;
  > label {
    font-size: 12px;
  }
}

.mdd-typehead-select {
  &--menuItems {
    &Selected {
      font-weight: 500 !important;
    }
  }

  &--noOptionsMessage {
    padding: 0.7rem;
  }

  &--input {
    display: flex;
    padding: 0;
    width: 100%;
  }

  &--valueContainer {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  &--menuPaper {
    position: absolute;
    z-index: 1;
    margin-top: 8px;
    left: 0;
    right: 0;
  }

  &--singleValue {
    font-size: 16px !important;
  }

  &--dropdownErrorText {
    color: $color-error-text;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 8px;
    min-height: 1em;
    line-height: 1em;
  }
}
