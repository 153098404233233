// // main container
.mdd-grid {
  height: 100%;
  // header
  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $mdd-table-header-height;
    margin: 0 auto;
    border-radius: 4px 4px 0 0;
    background-color: $header-color;

    & button {
      font-size: inherit;
      text-transform: none;
      background-color: $color-white;
      text-align: right;
      margin-right: 3rem;
      font-weight: 1;
      width: auto;
    }

    svg {
      font-size: 1.3rem;
      margin-right: 5px;
    }

    p {
      font-size: inherit;
      color: $color-black;
      font-weight: 1;
      margin-left: 3rem;
    }
  }

  // body
  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    height: 90%;
    width: 100%;
    margin: 0 auto;
    height: calc(100% - #{$mdd-table-header-height});

    > div:first-child {
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: 10px 20px 0 20px;
      height: 100%;
    }
  }

  &--with-menu {
    height: 95%;
    display: flex;
    flex-direction: column;

    > div:first-child {
      min-height: $mdd-table-header-height;
      background-color: $color-grey-400;

      p {
        color: $color-white;
        font-weight: 500;
      }
    }

    > div:nth-child(2) {
      height: calc(100% - 4rem);

      > div:first-child {
        padding: 1% 1% 0 1%;
      }
    }
  }
}
