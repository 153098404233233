.mdd-breadcrumb {
  display: flex;
  align-items: center;
  background-color: $header-color;
  color: $color-black;
  height: $mdd-table-header-height;

  & > :first-child {
    margin-left: 50px;
  }

  & > :last-child {
    overflow: visible;
  }

  & * {
    margin: 5px;
    color: currentcolor;
    max-width: 200px;
    @include text-ellipsis;
  }
}
