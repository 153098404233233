.mdd-header {
  flex-grow: 1;
  height: 5rem;
  margin-bottom: 1rem;

  &--user {
    color: black;
  }

  &--appbar {
    background-color: $color-white !important;
    border-bottom: 1px grey solid;
    color: white !important;

    &-contents {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &-logo {
      display: flex;
      align-items: center;
      width: 9rem;
      height: 2rem;
      padding: 1rem;
      box-sizing: initial;
    }

    &-icons {
      display: flex;
      align-items: center;
    }
  }

  &--tabs {
    $tabs-height: 58px;
    min-height: $tabs-height !important;
    margin-right: auto;
    padding-left: 2rem;

    &-indicator {
      background-color: lightskyblue !important;
    }

    &-scroller {
      height: $tabs-height;

      & > :first-child {
        background-color: $color-primary-100;
      }

      & > * {
        font-weight: 500 !important;
      }
    }

    & * {
      height: 100%;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      text-transform: none;
    }
  }
}
