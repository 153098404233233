// Used to move the acceptable scores icon a bit to the left.  For some reason
// it just looks to be a bit out of line which will drive me nuts everytime I see
// that menu so tweaking it.
%scores-position {
  position: relative;
  left: -2px;
}
.mdd-generic-menu {
  &--grid {
    height: 100%;
    box-shadow: $color-container-shadow;
    flex-wrap: nowrap !important;
  }

  &--container {
    height: 100%;
  }

  &--childComponentContainer {
    height: 100%;
  }

  &--appBar {
    flex: auto;
    vertical-align: center;
    height: 100%;

    &Open {
      width: calc(100% - #{$mdd-generic-menu-width-open});
    }

    &Close {
      width: calc(100% - #{$mdd-generic-menu-width-close});
    }
  }

  &--menuDrawerIcon {
    padding: 0px 0.5rem 0px 0.5rem !important;
  }

  &--divMenuOpenDrawerIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $header-color;
    height: $mdd-table-header-height;
    width: 100%;
  }

  &--divMenuCloseDrawerIcon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: $mdd-table-header-height;
  }

  &--drawer {
    flex-shrink: 0;
    overflow-x: hidden;
    overflow-y: auto;

    &Open {
      width: $mdd-generic-menu-width-open;
      border-top: 1px solid $header-color;
      > ul > #AcceptableScores {
        @extend %scores-position;
      }
    }

    &Close {
      width: $mdd-generic-menu-width-close;
      > ul > #AcceptableScores {
        @extend %scores-position;
      }
    }
  }

  &--menuItem {
    display: flex !important;
    justify-content: center !important;
    height: 3.5rem !important;
    text-transform: none !important;

    &Icon {
      width: 1.6rem;
      height: 1.6rem;

      &Wrapper {
        display: flex;
        position: relative;
      }
    }
    &--text {
      padding-left: 10px;
    }
  }

  &--disabledButtonLockIcon {
    height: 0.8rem;
    width: 0.8rem;
    background-color: $color-white;
    stroke-width: 0.5px;
    color: $color-grey-100;
    position: absolute;
    top: -10%;
    right: -5%;
  }

  &--disabledButtonLockIconFa {
    height: 0.8rem;
    width: 0.8rem;
    background-color: $color-white;
    stroke-width: 0.5px;
    color: $color-grey-100;
    position: absolute;
    top: -30%;
    right: -40%;
    opacity: 50%;
  }

  &--faIcon {
    color: rgb(100, 100, 100);
  }

  &--displayNone {
    display: none;
  }
}
