.error-modal {
  text-align: center;
  margin-top: 100px;

  &--heading {
    font-size: 24px;
  }

  &--message {
    font-size: 18px;
    margin-top: 10px;
  }

  &--link {
    margin-top: 20px;
    display: inline-block;
    font-size: 18px;

    a {
      text-decoration: none;
      color: black;
      font-weight: bold;
    }

    a:hover {
      color: #9e9e9e;
    }
  }
}
