.mdd-experience {
  &--container {
    height: calc(100% - 3.5rem - 57px);
    overflow-y: auto;
    border-left: 1px solid lightgray;
  }
  &--expand-collapse {
    text-align: right;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    align-items: flex-end;
    .status {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #707070;
      font-size: 14px;
    }
  }
  &--right-justify-italic {
    text-align: right;
    padding-right: 24px;
    padding-bottom: 12px;
    font-style: italic;
    font-size: 12px;
  }
  &--label {
    font-weight: bold;
  }
  &--page-header {
    color: #707070;
    font-size: 28px;
    padding: 24px 16px;
    border-left: 1px solid lightgray;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 24px;
      font-weight: 500;
    }

    .no-cp-access {
      font-weight: 600;
      font-size: 0.8125rem;
      margin-right: 16px;
      color: grey;
      svg {
        margin-right: 8px;
        font-size: 1.2rem;
      }
    }
  }
  &--no-experience {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid lightgray;
    height: calc(100% - 3.5rem - 57px);

    .text {
      padding: 12px 32px;
      border: 1px solid lightgray;
      border-radius: 2px;
    }
  }
  &--assign-button {
    height: 32px;
    text-transform: none !important;
  }
  &--flex-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    margin-top: -7px;
    justify-content: space-around;
    &--column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
    &--child-left {
      flex-basis: 50%;
      padding: 15px;
      min-width: 300px;
      line-height: 2rem;
      display: flex;
    }
    &--child-right {
      padding: 15px;
      min-width: 300px;
      line-height: 2rem;
      border: none;
      flex-basis: 50%;
    }
    &--child-fullwidth {
      flex-basis: 98%;
      padding: 0;
      border: 1px solid lightgray;
      border-radius: 3px;
    }
    &--child-bottom-spacing {
      margin-bottom: 24px;
    }
  }
  &--expand-collapse-div {
    color: #0267b8;
    cursor: pointer;
    & > :first-child {
      padding-right: 10px;
      padding-left: 20px;
    }
    & > :nth-child(2) {
      // separator
      color: lightgray;
      opacity: 0.5;
      padding-right: 10px;
      cursor: auto;
    }
  }
  &--fullwidth {
    width: 100%;
  }
  &--expand-collapse-disabled {
    color: #0267b8;
    opacity: 0.5;
    cursor: auto;
  }
  &--panel {
    &--scales {
      * > .tbl--container {
        margin-top: -24px !important; // !important needed to override MUI
        > .tbl {
          > .tbl--footer {
            display: none;
          }
          > .tbl--header {
            height: 4rem;
            border-bottom: 1px solid lightgray;
          }
        }
      }
    }
    &--content {
      > :first-child {
        padding-top: 0;
        margin-top: -5px;
        width: 100%;
      }
      &--label {
        font-size: 14px;
        font-weight: bold;
        padding-top: 10px;
        word-break: break-word;
      }
      &--text {
        padding-left: 10px;
        font-size: 14px;
        word-break: break-word;

        &--bold {
          font-weight: bold;
        }
      }
    }
    > div {
      border: 1px solid lightgray;
    }
  }

  &--bottom-panel {
    height: 100%;
    padding-top: 16px;
    &--header {
      font-size: 18px;
    }
    > div {
      border: 1px solid lightgray;
    }
  }
  &--override {
    &--cp-comment-icon {
      padding-left: 10px;
    }
  }
  &--button {
    height: 30px;
    width: 100px;
    padding: 0 !important;
    > span {
      text-transform: none;
      font-size: 12px;
    }
  }

  &--inactive-item {
    color: grey;
  }

  &--tbl-cell {
    display: inline;
  }

  &--indication-child {
    padding-left: 16px;
  }

  &--unassign-dialog {
    width: auto;

    &-content {
      padding: 0 !important;
    }
  }
}
#scaleExperienceInfiniteScroll {
  overflow-y: hidden;
}

#scaleExperience_tablebody .tbl--body-row {
  // remove the left margin for the first column so it lines up better
  :first-child {
    margin-left: 0;
  }
  // center Edit button in its cell
  :last-child {
    text-align: center;
  }
}
