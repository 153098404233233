.typography {
  width: 100%;
  height: 45px;
  display: flex;
  font-size: 1rem;
  font-family: "Noto Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 1;
  line-height: 1.6;
  align-items: center;
  letter-spacing: 0.0075em;
}
