.mdd-study-site-personnel {
  &--fab {
    top: -5px;
    right: 30px;
  }
  &--divider {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 95%;
  }
  &--inactive-chip {
    background-color: #cacaca !important;
    color: black !important;
  }
  &-div {
    &--chip {
      padding-top: 3rem;
      padding-bottom: 0.5rem;
    }
    &--link {
      overflow-wrap: break-word;
    }
    &--button {
      width: 100%;
    }
    &--flex-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
    }
    &--flex-child-left {
      flex-basis: 58%;
      border: 1px solid lightgray;
      padding: 15px;
      min-width: 300px;
      line-height: 2rem;
      display: flex;
      flex-direction: row;
      &--column1 {
        flex-basis: 55%;
      }
      &--column2 {
        flex-basis: 45%;
        padding-left: 5px;
      }
    }
    &--flex-child-right {
      flex-basis: 40%;
      border: 1px solid lightgray;
      padding: 15px;
      min-width: 300px;
      line-height: 2rem;
      > button > span > svg {
        color: white;
      }
      > .mdd-study-site-personnel--fab {
        position: absolute;
      }
      // size the protocol dropdowns correctly
      > .txtField--container > .mdd-form--text {
        margin-top: 2px;
        padding-bottom: 8px;
        > div {
          height: 32px;
          > div {
            padding-left: 8px;
          }
        }
      }
    }
    &--header {
      font-weight: 700;
      margin-bottom: 7px;
    }
    &--label {
      font-weight: 700;
      margin-top: 7px;

      &-no-chip {
        font-weight: 700;
        margin-top: 7px;
        padding-top: 3.5rem;
      }

      &-link {
        margin-bottom: -5px;
        font-weight: 700;
        margin-top: 7px;
      }
    }
    &--data {
      line-height: 1.5rem;
      overflow-wrap: break-word;
    }
  }
}
