.mdd-study-scale {
  &-div {
    &--container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 60vw;
      height: 1.25rem;
      margin-top: 1.5rem;
    }
    &--caption {
      flex-basis: 20%;
    }
    &--value {
      flex-basis: 80%;
      line-height: 1.2rem;
    }
  }
}
#chkStudyScaleActiveDiv {
  margin-top: 1rem;
}
