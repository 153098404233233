.mdd-no-data-div {
  &--container {
    margin-top: 16px;
    text-align: center;
    width: 100%;
    margin-bottom: 7px;
  }
  &--text {
    display: inline-block;
    color: gray;
  }
}
