.mdd-personnel {
  &-form {
    &--container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 20px 0px 20px 25px;
      width: 100%;
    }
  }
  &-studies {
    &--paper {
      width: 98%;
      border: 2px solid ghostwhite;
      &-header {
        text-align: center;
        &--caption {
          width: 100%;
          background-color: lightgray;
          border-radius: 25px;
        }
      }
    }
    &--container {
      width: 99%;
      > .tbl--container {
        > .tbl {
          > .tbl--body {
            min-height: 2.5rem;
            > .infinitescroll--container {
              > .infinitescroll {
                overflow-y: hidden;
                > .infinitescroll--content {
                  > .tbl--body-row {
                    > .tbl--body-cell {
                      margin-left: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &--link {
      cursor: pointer;
    }
  }
  &-tbl {
    &--container {
      height: 50vh;
      width: 90vw;
    }

    &--footer {
      display: none;
    }

    &--selected {
      background-color: #0093d0;
    }
  }
  &--container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 900px;
    line-height: 1.25rem;
    &--column {
      flex-direction: column;
      flex-basis: 49%;
      > :first-child {
        padding-top: 0;
      }
    }
    > div:nth-child(2) {
      border-left: lightgray 1px solid;
      padding-left: 7px;
    }
  }

  &-div {
    &--indent {
      text-indent: -15px;
      margin-left: 15px;
    }
    &--title {
      font-size: 1.2rem;
      font-weight: bold;
    }
    &--container {
      line-height: 1.25rem;

      > .txtField--container {
        > div {
          margin-top: 5px;
          > div {
            > input {
              // ellipsis for overflow on text fields
              text-overflow: ellipsis;
              padding: 5px;
            }
          }
        }
      }
    }
    &--caption {
      font-weight: bold;
      margin-top: 0.5rem;
    }
    &--caption-padded {
      padding-top: 15px;
      font-weight: bold;
      margin-top: 0.5rem;
    }
    &--value {
      padding-top: 0.5rem;
      overflow-wrap: break-word;
    }
    &--editable-value {
      margin-top: 3px;
      width: 100%;
    }

    &--search {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      & > :first-child {
        flex-basis: 30%;
        align-self: flex-end;
        margin-bottom: 8px;
      }

      & > :last-child {
        flex-basis: 65%;
      }
    }

    &--metadata {
      // .validated-selectchip-field__select
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;

      > * {
        margin: 5px !important;
      }

      // @media (max-width: 640px) {
      //     flex-direction: column;
      // }
    }
  }
}
