.mdd-form {
  position: relative;
  height: calc(100% - #{$mdd-table-header-height});
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;

  &--loading {
    position: absolute;
    top: 5px;
    right: 5px;
    margin: 1rem;
  }

  &--fullheight {
    height: 100%;
  }

  &--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 50px 20px 50px;
    width: 100%;

    > div {
      > .dialog-button-hidden {
        display: none;
      }
    }
  }

  &--body {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;

    > * {
      margin-bottom: 1rem !important;

      width: 320px;

      @media (max-width: 37.5em) {
        width: 90%;
      }
    }

    & * {
      min-width: 0;
    }
  }

  &--body-typeahead {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    height: 100%;

    > * {
      margin-bottom: 1rem !important;

      width: 320px;

      @media (max-width: 37.5em) {
        width: 90%;
      }
    }

    & * {
      min-width: 0;
    }
  }

  &--title {
    display: flex;
    width: 95%;
    height: 5%;
    margin-bottom: 10px;

    &-site-number {
      font-size: 1rem;
      font-weight: 700;
      color: $color-grey-400 !important;
      padding-left: 0.3rem;
    }
  }

  &--title > p {
    font-size: 1rem;
    font-weight: 700;
  }

  &--typeahead {
    & > div {
      width: 100%;
    }

    &-label {
      padding: 0;
      font-size: 12px;
      color: #2fa7d9;
    }

    &-label-error {
      padding: 0;
      font-size: 12px;
      color: #f44336;
    }
  }

  &--text {
    display: inline-block !important;

    & > div {
      width: 100%;
    }

    & > label {
      color: #2fa7d9;
    }
  }
  &--dialogText {
    display: inline-block !important;
    width: 300px;
    margin-bottom: 15px !important;

    & > div {
      width: 100%;
    }

    & > label {
      color: #2fa7d9;
    }
  }

  &--multiline-text {
    display: block !important;

    & > div {
      width: 100%;
    }

    & > label {
      color: #2fa7d9;
    }
  }

  &__button {
    position: absolute !important;
    bottom: 0;
    right: 0;
    width: 10%;
    min-width: 64px;
    text-shadow: none;
    font-weight: normal;
    text-transform: none !important;

    &--container {
      position: relative;
      height: 2rem;
      margin-top: 3%;
    }
  }
}
