body {
  flex: 1;
  overflow: hidden;
}

main {
  height: calc(
    100vh - 7rem
  ); /* window height - mdd-header (height 9rem + padding 1rem) */
  margin-right: 1rem;

  > div {
    display: block;
    justify-content: center;
    min-height: 500;
    height: 100%;
  }

  > span {
    position: absolute;
    top: 50%;
    left: calc(50% - 25rem);
    color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50rem;
  }
}

button {
  height: 40px;
  color: $color-black;

  & svg {
    color: rgba(0, 0, 0, 0.54);
  }

  &.button-primary {
    font-size: 16px;
  }
  &.button-secondary {
    font-size: 14px;
  }
}

.svgEditIconPointer {
  cursor: "pointer";
}

.absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
}

.displayRelative {
  position: relative;
}

.notVisible {
  visibility: hidden;
}
.displayNone {
  display: none;
}

label {
  font-size: 0.9rem;
  font-family: "Noto Sans" !important;
}

div {
  font-family: "Noto Sans" !important;
}
p {
  font-family: "Noto Sans" !important;
}

li {
  font-size: 0.9rem;
}

#mddDialogActions > button {
  text-transform: none;
}
