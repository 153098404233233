.mdd-res-config {
  &-instructions {
    padding-left: 2rem;
    padding-top: 1rem;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 0.9rem;
    &--link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &-tableContainer {
    height: 65vh;
    .tableHead {
      height: 3rem;
    }

    .mdd-form--container {
      padding: 20px 2rem;
    }
  }
  &--paragraph {
    margin-top: 1.5rem;
    &--text {
      margin-top: 8px;
    }
  }
  &-dialog {
    width: 45vw;
    &--header {
      font-weight: bold;
    }
  }
}

// Moving the button to the right and title to the left so it looks cleaner
#btnresConfigContainerAdd {
  margin-right: 1rem;
}
#presConfigContainerHeaderTitle {
  margin-left: 1rem;
}
