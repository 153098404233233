.mdd-comments {
  &--icon-wrapper {
    position: absolute;
    bottom: 6px;
    right: 6px;
  }
  &--icon-fab {
    > span > svg {
      color: white;
    }
  }
  &--show-more-less {
    color: #0267b8;
    padding-top: 4px;
    cursor: pointer;
  }
  &--no-comments {
    &--container {
      text-align: center;
    }
    &--text {
      color: gray;
      padding-top: 2rem;
      width: 100%;
      display: inline-block;
    }
  }

  &--edit-comment {
    &--input {
      padding-right: 16px !important; // front-end-lib has !important so need to override it
      margin-top: 10px !important;
      > div {
        padding: 12px;
      }
    }
  }
  &--dialog-actions {
    padding-right: 12px;
  }

  &--container {
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &--header {
    font-weight: bold;
    font-size: 20px;
    padding: 9px;
    border-bottom: 1px solid lightgray;
    flex: 0 0 2rem;
    &--filter {
      position: absolute;
      right: 10px;
    }
  }
  &--list {
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1 1 auto;
    width: 25vw;
    @media (max-width: 1480px) {
      width: 33vw;
    }
    @media (max-width: 1200px) {
      width: 40vw;
    }
  }
  &--add-comment {
    flex: 0 0 10rem;
    width: 100%;
    border-top: 1px solid lightgray;
    background-color: #f5f5f5;
    overflow-x: hidden;
    &--input {
      text-align: center;
      > div {
        padding: 7px;
        width: 95%;
        display: inline-block;
        background-color: white;
        > div > textarea {
          word-break: break-all;
        }
      }
    }
    > #mddDialogActions {
      padding-right: 16px;
      margin-top: 0px;
    }
  }
  &--comment {
    padding: 9px 5px 0 7px;
    font-size: 1rem;

    &--person-div {
      display: flex;
      &--icon {
        color: #3e3e3e;
        flex: 0 0 20px;
      }
      &--name {
        position: relative;
        top: -2px; // icon next to the name throws off the alignment
        padding-left: 5px;
        font-weight: bold;
        color: #3e3e3e;
        flex: 1 1 auto;
      }
      &--editicon {
        flex: 0 0 32px;
        color: #3e3e3e;
        cursor: pointer;
        > svg {
          font-size: 18px;
          position: relative;
          top: -2px;
        }
      }
      &--editing-icons {
        flex: 0 0 62px;
        padding-right: 16px;
        &--done {
          color: #04871c;
          cursor: pointer;
          padding-right: 10px;
          > svg {
            font-size: 18px;
            position: relative;
            top: -2px;
          }
          &-disabled {
            color: lightgray;
            padding-right: 10px;
            > svg {
              font-size: 18px;
              position: relative;
              top: -2px;
            }
          }
        }
        &--cancel {
          color: #cf4234;
          cursor: pointer;
          > svg {
            font-size: 18px;
            position: relative;
            top: -2px;
          }
        }
      }
    }
    &--info-div {
      padding-left: 25px;
      &--text {
        color: #747474;
      }
      &--comment-text {
        padding: 7px 0 5px 0;
        overflow-wrap: break-word;
        white-space: break-spaces;
      }
      &--edited {
        padding-left: 8px;
        font-size: 14px;
      }
    }
  }
}
