.mdd-sponsor {
  &-tbl {
    &--header-row {
      margin-bottom: 0px !important;
      padding-bottom: 10px;

      &:first-child {
        * {
          width: 250px;
        }
      }

      &:last-child {
        position: relative;

        > :first-child {
          position: absolute;
          left: 2%;
        }

        > :last-child {
          position: absolute;
          right: 2%;

          > * {
            justify-content: center;
          }
        }
      }
    }

    &--body-row {
      position: relative;

      & > :first-child {
        /* 100% - (Sponsor Name Change History header label width 235px + padding 16px) - (tbl--body-cell left 2% + right 2%) */
        width: calc(100% - 251px - 4%);
        position: absolute;
        left: 2%;
        margin: initial;
      }

      & > :last-child {
        position: absolute;
        right: 150px;
        border-radius: 50%;

        & > :first-child {
          border-radius: 50%;
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background-color: gray;
          }
        }
      }
    }

    &--body-cell {
      text-overflow: ellipsis;
      overflow: hidden;

      &:last-child {
        overflow: visible;
      }
    }

    &-history {
      min-height: 100px;
      width: 600px;

      & > :first-child {
        height: 2rem;
      }

      & > :nth-child(2) {
        padding: 0px;
      }

      & > :last-child {
        display: none;
      }

      & * {
        overflow-y: auto !important;
      }

      &--header {
        &-row {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          color: $color-white;
          width: 100%;
        }
      }

      &--body {
        &-row {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin: 0;
          border-bottom: none;
        }
      }

      &--progress {
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
      }
    }
  }
}
