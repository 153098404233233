.mdd-res-config-view {
  .metadata {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 16px;
  }
  .item {
    padding-bottom: 8px;
    width: 33%;
  }
  .header {
    font-weight: bold;
    padding-right: 6px;
    padding-bottom: 8px;
  }
  &--panel {
    margin-left: 0;
    margin-right: -16px; // nested panel containers screw up the alignment so move it a bit
  }
  &--panel > .mdd-panel-container--panel-half {
    border: 1px solid black;
  }
  &--panel > .mdd-panel-container--panel-onethird {
    border: 1px solid black;
  }
  &--panel-format {
    padding: 0;
    display: block;
    margin-bottom: 24px;
  }
  &--panel-format-left {
    padding: 0;
    margin: 0;
  }
  &--panel-format-right {
    margin-top: 24px;
    padding: 0;
  }
  &--panel-description {
    padding-bottom: 6px;
  }
  &--panel-description-flex {
    display: flex;
    justify-content: space-between;
  }
  &--panel-description-padding {
    padding-top: 8px;
  }
  &--panel-description-inactive {
    font-size: 14px;
  }
  &--panel-scales {
    overflow-y: auto;
    max-height: 60vh;
  }
  &--panel-nonscales {
    overflow-y: auto;
    max-height: 30vh;
  }
  &--dialog {
    width: auto;
  }
  .value {
    font-size: 14px;
  }
  .childIndication.value {
    padding-left: 12px;
    font-size: 14px;
  }
  .parentIndication.value {
    font-size: 16px;
  }
  .inactive-item {
    color: grey;
  }
}
